<template>
  <let-it-snow
    v-bind="snowConf"
    :show="show"
    class="pointer-events-none"
  />
</template>

<script>
export default {
  name: 'Snow',
  data() {
    return {
      snowConf: {
        windPower: -3,
        speed: 3,
        count: 150,
        size: 0.1,
        opacity: 1,
        color: '#def4ff',
      },
      show: false,
    }
  },
  mounted() {
    this.show = true
  },
}
</script>
